import React from "react";
import InternalLayout from "../layouts/internallayout";
import styled from "styled-components";
import {DesktopTwoColumns} from "../styles/shared";
import Seo from "../components/seo";
import TourVideo from "../components/TourVideo";

const Container = styled.div`
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 4em 1em;
    position: relative;
    overflow: hidden;
`

const Bookings = () => (
    <InternalLayout>
        <Seo title="Make a booking with Element Physiotherapy"/>
        <Container>
            <h1>Bookings</h1>

            <p>The Element Physio clinic is
                located on the ground floor, Element building, 200 The Entrance Road, Erina. Undercover parking is
                accessible via Karalta Lane – Next door to I-MED Radiology. The Element building is home to a number of
                health providers including Neurologists, Neurosurgeons, Orthopaedic surgeons, General Practitioners,
                Nurses, Dietician, Speech pathologists &amp; Occupational therapists. We understand we are a part of
                your bigger healthcare picture and will happily liaise with other professions where necessary to better
                achieve your health goals.</p>

            <p><strong>Erina Clinic</strong></p>
            <p><strong>Phone:</strong> (02) 43 65 56 46</p>
            <p><strong>Email:</strong> <a
                href="mailto:info@elementphysiotherapy.com.au">info@elementphysiotherapy.com.au</a></p>
            <p><strong>Address:</strong> Element
                Building, 1.04/200 The Entrance Rd, Erina NSW 2250</p>
            <br/>
            <DesktopTwoColumns>
                <div>
                    <div className="video-wrapper">
                        <TourVideo />
                    </div>
                </div>
                <div>
                    <div className="video-wrapper">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.540321755411!2d151.38628029999998!3d-33.4352268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b72ca70beb9a2c5%3A0x1770b3a9f572a4bd!2sErina%20Physiotherapy%20Sports%20and%20Exercise%20Rehabilitation%20Centre!5e0!3m2!1sen!2sau!4v1571909921709!5m2!1sen!2sau"
                            frameBorder="0"></iframe>
                    </div>
                </div>
            </DesktopTwoColumns>
        </Container>
    </InternalLayout>
    )


export default Bookings
